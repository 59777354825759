.LessonTypePageParams__container{
  display: flex;
  width: 100%;
  background: white;
  height: 60px;
  align-items: center;
  padding: 0 10px;
  transition: .3s linear all;
  justify-content: space-between;
}

.LessonTypePageParams__addEntity{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
}

.LessonTypePageParams__addEntity:hover{
  background: #fff;
  color: #19ad4b;
}

.LessonTypePageParams__wrapper{
  display: flex;
  align-items: center;
  height: 100%;
}

.LessonTypePageParams__input{
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 300px;
  padding: 8px 12px;
}

.LessonTypePageParams__input:focus{
  border: 1px solid #2F80ED;
}