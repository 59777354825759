.SoulAnswer__item__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
}

.FimboLikes__item__row div{
  margin: 0 2px;
  overflow-x: auto;
}

.SoulAnswer__header{
  font-weight: 600;
  margin-top: 40px;
  padding-bottom: 5px;
}

.SoulAnswer__item__stat{
  width: 150px;
}

.SoulAnswer__item__name{
  width: 300px;
}

.SoulAnswer__diagram__mainContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.SoulAnswer__diagram__container{
  display: flex;
  align-items: center;
  flex-direction: column;
}