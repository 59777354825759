.FimboItemPage__container{
  width: 100%;
  position: relative;
}

.FimboItemPage__form{
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.5rem;
  padding-bottom: 70px;
}

.FimboItemPage__form_left{
  width: calc(50% - 15px);
  margin-left: 10px;
  margin-right: 5px;
}
.FimboItemPage__form_right{
  width: calc(50% - 15px);
  margin-right: 10px;
  margin-left: 5px;
}

.FimboItemPage__form__button{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.FimboItemPage__form__button:hover{
  background: #fff;
  color: #19ad4b;
}

.FimboItemPage__form__item{
  min-height: 40px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background: white;
}

.FimboItemPage__form__item_text{
  width: 150px;
}

.FimboItemPage__form__item_input, .FimboItemPage__form__lepest__input, .FimboItemPage__form__item_textarea{
  flex: 1 1 auto;
  margin-left: 10px;
  border: 0;
  background: none;
  border-bottom: 1px solid grey;
  padding: 8px 12px 4px 12px;
}

.FimboItemPage__form__lepest__input{
  width: 100%;
  padding: 8px 2px 4px 2px;
  margin: 0;
  flex: 0;
}

.FimboItemPage__form__lepest__item__row__item{
  display: flex;
  flex-direction: column;
  width: 12%;
  margin-right: 10px;
  justify-content: space-between;
}

.FimboItemPage__form__item_input:focus, .FimboItemPage__form__lepest__input:focus, .FimboItemPage__form__item_textarea:focus{
  border-bottom: 1px solid #2F80ED;
}

.FimboItemPage__form__item_textarea{
  font-size: 1.5rem;
  resize: none;
}

.FimboItemPage__form__lepest__item{
  min-height: 40px;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background: white;
}

.FimboItemPage__form__lepest__item__row{
  display: flex;
  justify-content: space-between;
}

.FimboItemPage__form__button_save, .FimboItemPage__form__button_delete{
  font-size: 1.5rem;
  background: grey;
  border: 1px solid grey;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
  width: 120px;
}

.FimboItemPage__form__button_save{
  margin-right: 10px;
}

.FimboItemPage__form__button_save:hover, .FimboItemPage__form__button_delete:hover{
  background: #fff;
  color: black;
}

.FimboItemPage__form__button__container{
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}

.FimboItemPage__form__item_relative{
  position: relative;
}

.FimboItemPage__form__item_color{
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}



.FimboItemPage__form__item__plusMinus{
  display: flex;
}

.FimboItemPage__form__item__plusMinus__plus, .FimboItemPage__form__item__plusMinus__minus{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  font-size: 2rem;
  transition: .3s linear all;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.FimboItemPage__form__item__plusMinus__minus{
  background: #eb4242;
  border: 1px solid #eb4242;
  margin-left: 5px;
}

.FimboItemPage__form__item__plusMinus__minus:hover{
  background: white;
  color: #eb4242;
}

.FimboItemPage__form__item__plusMinus__plus{
  background: #19AD4B;
  border: 1px solid #19AD4B;
}

.FimboItemPage__form__item__plusMinus__plus:hover{
  background: white;
  color: #19AD4B;
}

.FimboItemPage__form__item__column{
  display: flex;
  flex-direction: column;
}

.FimboItemPage__form__item__column__item{
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 2px 0;
}

.FimboItemPage__form__item_column__circle, .FimboItemPage__form__item_column__circle_green{
  margin-left: 10px;
  border: 1px solid grey;
  border-radius: 20px;
  width: 20px;
  height: 20px;
}

.FimboItemPage__form__item_column__circle_green{
  border: 1px solid #19AD4B;
  background: #19AD4B;
}