.LessonItemPage__notesListen__container{
  background: white;
  border-radius: 20px;
  position: relative;
  padding: 10px 10px 50px 10px;
}

.LessonItemPage__notesListen__background{
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LessonItemPage__notesListen__cross {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s linear;
  opacity: 0.5;
}
.LessonItemPage__notesListen__cross:after, .LessonItemPage__notesListen__cross:before{
  background: #000;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  top: 10px;
  width: 20px;
}

.LessonItemPage__notesListen__cross::before{
  transform: rotate(45deg);
}

.LessonItemPage__notesListen__cross::after {
  transform: rotate(-45deg);
}

.LessonItemPage__notesListen__cross:hover{
  opacity: 1;
}

.LessonItemPage__notesListen__fimbo{
  display: flex;
  align-items: center;
}

.LessonItemPage__notesListen__fimbo__select{
  font-size: 1.5rem;
  border: 1px solid #2f80ed;
  outline: 0;
  border-radius: 5px;
  font-family: inherit;
  margin-left: 5px;
  padding: 2px 4px 2px 0;
}

.LessonItemPage__notesListen__mode_play{
  background: #19AD4B;
  font-size: 1.5rem;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  transition: .3s linear all;
  border-radius: 5px;
  color: white;
}

.LessonItemPage__notesListen__mode_pause{
  background: #2f80ed;
}

.LessonItemPage__notesListen__notes__container{
  max-width: 500px;
  overflow: scroll;
  max-height: 350px;
  padding: 10px 10px 10px 40px;
  position: relative;
}

.LessonItemPage__notesListen__notes__rows{
  display: flex;
  flex-wrap: wrap;
}

.LessonItemPage__notesListen__notes__count{
  position: absolute;
  top: 50px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #2f80ed;
  border-radius: 5px;
  border: 1px solid #2f80ed;
  color: white;
  left: 0;
}

.LessonItemPage__notesListen__notes__column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LessonItemPage__notesListen__notes__container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.LessonItemPage__notesListen__notes__container::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}

.LessonItemPage__notesListen__notes__animate{
  background: rgba(91, 89, 235, 0.3);
}
.LessonItemPage__notesListen__notes__played{
  background: #e6e6e6;
}

.LessonItemPage__notesListen__note{
  width: 20px;
  display: flex;
  flex-direction: column;
  height: 40px;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid orange;
  justify-content: center;
  align-items: center;
  transition: .15s all ease-in-out;
}

.LessonItemPage__notesListen__note_noRight{
  border-right: none;
}

.LessonItemPage__notesListen__note_top{
  width: 100%;
  height: 50%;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LessonItemPage__notesListen__note_bot{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LessonItemPage__notesListen__sound, .LessonItemPage__notesListen__tact{
  position: absolute;
  right: 15px;
  bottom: 20px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.LessonItemPage__notesListen__sound{
  right: auto;
  left: 15px;
  flex-direction: row-reverse;
}

.LessonItemPage__notesListen__circle{
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: #19AD4B 1px solid;
  margin: 0 5px;
  cursor: pointer;
}

.LessonItemPage__notesListen__circle__filled{
  background: #19AD4B;
}