.LessonTypeItemRow__row {
  width: 100%;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  background: white;
  padding: 5px 10px;
}

.LessonTypeItemRow__row__color{
  background: rgb(238, 246, 255)
}

.LessonTypeItemRow__row > * {
  display: flex;
  align-items: center;
  margin: 0 5px;
  overflow-x: auto;
  overflow-y: hidden;
}

.LessonTypeItemRow__row > *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.LessonTypeItemRow__row > *::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

.LessonTypeItemRow__row__visible {
  width: 40px;
}

.LessonTypeItemRow__row__id{
  width: 40px;
}

.LessonTypeItemRow__row__name{
  width: 120px;
}

.LessonTypeItemRow__row__path{
  width: 100px;
}

.LessonTypeItemRow__row__edit{
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}