@import 'normalize.css';

:focus, :active {
    outline: none
}

a:focus, a:active {
    outline: none
}

html {
    border: 0;
    cursor: default;
    font-size: 10px;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    background: #ebebeb;
}

body {
    border: 0;
    cursor: default;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

input, button, textarea {
    font-family: inherit;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    padding: 0;
    border-radius: 0;
}

*, *::before, *::after {
    box-sizing: border-box;
}

a, a:visited, a:hover {
    text-decoration: none;
    cursor: pointer
}

h1 {
    font-size: inherit;
    font-weight: inherit;
}

input{
    font-size: 1.5rem;
}
