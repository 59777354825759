.AnalyticPage__container{
  width: 100%;
  padding: 0 20px 20px;
  font-size: 1.5rem;

}

.AnalyticLikes__container{
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
}

.AnalyticLikes__header{
  font-weight: 600;
  margin-bottom: 15px;
}

.Analytic__download{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
  margin-top: 30px;
  display: inline-block;
}

.Analytic__download:hover{
  background: #fff;
  color: #19ad4b;
}