.LessonLikes__item__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 2px;
}

.LessonLikes__item__row div{
  margin: 0 2px;
  overflow-x: auto;
}

.LessonLikes__item__row div::-webkit-scrollbar{
  width: 3px;
  height: 3px;
}

.LessonLikes__item__row div::-webkit-scrollbar-thumb{
  background-color: #000000;
  border-radius: 10px;
}

.LessonLikes__item__name{
  width: 240px;
  white-space: nowrap;
}

.LessonLikes__item__likeCount{
  width: 160px;
}

.LessonLikes__find__container{
  margin-bottom: 10px;
}

.LessonLikes__find{
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 300px;
  padding: 8px 12px;
}
.LessonLikes__find:focus{
  border: 1px solid #2F80ED;
}