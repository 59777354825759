.AudioItemPage__container{
  width: 100%;
  position: relative;
}

.AudioItemPage__form{
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.5rem;
  padding-bottom: 70px;
}

.AudioItemPage__form_left{
  width: calc(50% - 15px);
  margin-left: 10px;
  margin-right: 5px;
}
.AudioItemPage__form_right{
  width: calc(50% - 15px);
  margin-right: 10px;
  margin-left: 5px;
}

.AudioItemPage__form__button__container{
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}

.AudioItemPage__form__button_save, .AudioItemPage__form__button_delete{
  font-size: 1.5rem;
  background: grey;
  border: 1px solid grey;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
  width: 120px;
}

.AudioItemPage__form__button_save{
  margin-right: 10px;
}

.AudioItemPage__form__button_save:hover, .AudioItemPage__form__button_delete:hover{
  background: #fff;
  color: black;
}

.AudioItemPage__form__item{
  min-height: 40px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background: white;
}

.AudioItemPage__form__item_text{
  width: 150px;
}

.AudioItemPage__form__item_input{
  flex: 1 1 auto;
  margin-left: 10px;
  border: 0;
  background: none;
  border-bottom: 1px solid grey;
  padding: 8px 12px 4px 12px;
}

.AudioItemPage__form__item_input:focus{
  border-bottom: 1px solid #2F80ED;
}


.AudioItemPage__form__item__column{
  display: flex;
  flex-direction: column;
}

.AudioItemPage__form__item__column__item{
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 2px 0;
}

.AudioItemPage__form__item_column{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AudioItemPage__form__item_row{
  display: flex;
  width: 100%;
  margin-top: 5px;
  padding: 3px 10px;
}

.AudioItemPage__form__item__autoinput{
  padding: 5px 8px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-right: 5px;
  cursor: pointer;
  transition: .3s linear all;
}

.AudioItemPage__form__item__autoinput:hover{
  background: #eeeeee;
}

.AudioItemPage__form__item_column__circle, .AudioItemPage__form__item_column__circle_green{
  margin-left: 10px;
  border: 1px solid grey;
  border-radius: 20px;
  width: 20px;
  height: 20px;
}

.AudioItemPage__form__item_column__circle_green{
  border: 1px solid #19AD4B;
  background: #19AD4B;
}

.AudioItemPage__form__notes__listen__button{
  font-size: 1.5rem;
  background: #2f80ed;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
  transition: .3s linear all;
  border: 1px solid #2f80ed;
}

.AudioItemPage__form__notes__listen__button:hover{
  background: white;
  color: #2f80ed;
}

.AudioItemPage__form__notes__listen__button_container{
  width: 100%;
}