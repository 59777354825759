.AudioPageParams__container{
  display: flex;
  width: 100%;
  background: white;
  height: 60px;
  align-items: center;
  padding: 0 10px;
  transition: .3s linear all;
  justify-content: space-between;
}
.AudioPageParams__container_bigHeight{
  height: 110px;
}

.AudioPageParams__input{
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 300px;
  padding: 8px 12px;
}

.AudioPageParams__input:focus{
  border: 1px solid #2F80ED;
}

.AudioPageParams__fimbos,.AudioPageParams__lessons, .AudioPageParams__genres{
  font-size: 1.2rem;
  margin-left: 5px;
  cursor: pointer;
  padding: 10px 0;
}

.AudioPageParams__ArrowReverse{
  transform: rotate(180deg);
}

.AudioPageParams__Arrow{
  transition: .2s linear all;
  margin-left: 5px;
}

.AudioPageParams__fimbos__list, .AudioPageParams__lessons__list, .AudioPageParams__genres__list{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  height: 100%;
  background: white;
  overflow-y: scroll;
  font-size: 1.4rem;
  margin-left: 5px;
  margin-top: 5px;
  transition: all .3s linear;
}
.AudioPageParams__genres__list{
  margin-right: 5px;
}

.AudioPageParams__list__name{
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 5px;
}

.AudioPageParams__fimbos__list::-webkit-scrollbar, .AudioPageParams__lessons__list::-webkit-scrollbar,
.AudioPageParams__genres__list::-webkit-scrollbar, .AudioPageParams__list__name::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}

.AudioPageParams__fimbos__list::-webkit-scrollbar-thumb, .AudioPageParams__lessons__list::-webkit-scrollbar-thumb,
.AudioPageParams__genres__list::-webkit-scrollbar-thumb, .AudioPageParams__list__name::-webkit-scrollbar-thumb{
  background-color: #000000;
  border-radius: 10px;
}

.AudioPageParams__fimbos__list__item, .AudioPageParams__lessons__list__item, .AudioPageParams__genres__list__item{
  display: flex;
  cursor: pointer;
  align-items: center;
}

.AudioPageParams__fimbos__list__greenNo, .AudioPageParams__fimbos__list__green,
.AudioPageParams__lessons__list__greenNo, .AudioPageParams__lessons__list__green,
.AudioPageParams__genres__list__greenNo, .AudioPageParams__genres__list__green{
  border: 1px solid grey;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.AudioPageParams__fimbos__clearAll, .AudioPageParams__lessons__clearAll, .AudioPageParams__genres__clearAll{
  color: #2F80ED;
  margin-top: 5px;
  cursor: pointer;
}

.AudioPageParams__fimbos__list__green, .AudioPageParams__lessons__list__green, .AudioPageParams__genres__list__green{
  border: 1px solid #19AD4B;
  background: #19AD4B;
}

.AudioPageParams__wrapper{
  display: flex;
  align-items: center;
  height: 100%;
}

.AudioPageParams__addEntity{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
}

.AudioPageParams__addEntity:hover{
  background: #fff;
  color: #19ad4b;
}