.SoulPageParams__container{
  display: flex;
  width: 100%;
  background: white;
  height: 60px;
  align-items: center;
  padding: 0 10px;
  transition: .3s linear all;
  justify-content: space-between;
}
.SoulPageParams__container_bigHeight{
  height: 110px;
}

.SoulPageParams__input{
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 300px;
  padding: 8px 12px;
}

.SoulPageParams__input:focus{
  border: 1px solid #2F80ED;
}

.SoulPageParams__fimbos{
  font-size: 1.2rem;
  margin-left: 5px;
  cursor: pointer;
  padding: 10px 0;
}

.SoulPageParams__ArrowReverse{
  transform: rotate(180deg);
}

.SoulPageParams__Arrow{
  transition: .2s linear all;
  margin-left: 5px;
}

.SoulPageParams__fimbos__list{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  height: 100%;
  background: white;
  overflow-y: scroll;
  font-size: 1.4rem;
  margin-left: 5px;
  margin-top: 5px;
  transition: all .3s linear;
}

.SoulPageParams__list__name{
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 5px;
}

.SoulPageParams__fimbos__list::-webkit-scrollbar, .SoulPageParams__list__name::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}

.SoulPageParams__fimbos__list::-webkit-scrollbar-thumb, .SoulPageParams__list__name::-webkit-scrollbar-thumb{
  background-color: #000000;
  border-radius: 10px;
}

.SoulPageParams__fimbos__list__item{
  display: flex;
  cursor: pointer;
  align-items: center;
}

.SoulPageParams__fimbos__list__greenNo, .SoulPageParams__fimbos__list__green{
  border: 1px solid grey;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.SoulPageParams__fimbos__clearAll{
  color: #2F80ED;
  margin-top: 5px;
  cursor: pointer;
}

.SoulPageParams__fimbos__list__green{
  border: 1px solid #19AD4B;
  background: #19AD4B;
}

.SoulPageParams__wrapper{
  display: flex;
  align-items: center;
  height: 100%;
}

.SoulPageParams__addEntity{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
}

.SoulPageParams__addEntity:hover{
  background: #fff;
  color: #19ad4b;
}