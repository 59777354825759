.AreYouSure__background{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.AreYouSure__window{
  width: 350px;
  height: 200px;
  background: white;
  display: flex;
  border-radius: 20px;
  padding: 30px;
  font-size: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
}

.AreYouSure__question{
  text-align: center;
}

.AreYouSure__position{
  display: flex;
  justify-content: space-between;
}

.AreYouSure__confirm, .AreYouSure__reject{
  border: 1px solid grey;
  background: grey;
  color: white;
  transition: .3s linear all;
  border-radius: 5px;
  width: 48%;
  padding: 8px 12px;
  text-align: center;
  cursor: pointer;
  font-size: 1.5rem;
}

.AreYouSure__confirm:hover, .AreYouSure__reject:hover{
  background: white;
  color: grey;
}