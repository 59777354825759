.RecoverItemRow__row {
  width: 100%;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  background: white;
  padding: 5px 10px;
}
.RecoverItemRow__row_empty{
  padding: 20px 10px;
}

.RecoverItemRow__row__color{
  background: rgb(238, 246, 255)
}

.RecoverItemRow__row > * {
  display: flex;
  align-items: center;
  margin: 0 5px;
  overflow-x: auto;
  overflow-y: hidden;
}

.RecoverItemRow__row > *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.RecoverItemRow__row > *::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

.RecoverItemRow__row__id{
  width: 40px;
}

.RecoverItemRow__row__name{
  width: 300px;
}

.RecoverItemRow__button__size{
  width: 140px;
}

.RecoverItemRow__button{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  transition: all .3s linear;
  cursor: pointer;
  width: 140px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RecoverItemRow__button:hover{
  background: #fff;
  color: #19ad4b;
}

.RecoverPage__types{
  display: flex;
  background: white;
  padding: 10px;
  font-size: 1.5rem;
  gap: 10px;
  align-items: center;
}
.RecoverPage__types__button{
  background: #2f80ed;
  border: 1px solid #2f80ed;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  transition: all .3s linear;
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RecoverPage__types__button:hover{
  background: #fff;
  color: #2f80ed;
}

.RecoverPage__types__button__selected{
  background: #0DAEDE;
  border: 1px solid #0DAEDE;
}

.RecoverPage__types__button__selected:hover{
  color: #0DAEDE;
}