.GenreAddPage__container{
  width: 100%;
  position: relative;
}

.GenreAddPage__form{
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.5rem;
  padding-bottom: 70px;
}

.GenreAddPage__form_left{
  width: calc(50% - 15px);
  margin-left: 10px;
  margin-right: 5px;
}
.GenreAddPage__form_right{
  width: calc(50% - 15px);
  margin-right: 10px;
  margin-left: 5px;
}

.GenreAddPage__form__button{
  font-size: 1.5rem;
  background: #19ad4b;
  border: 1px solid #19ad4b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  transition: all .3s linear;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.GenreAddPage__form__button:hover{
  background: #fff;
  color: #19ad4b;
}

.GenreAddPage__form__item{
  min-height: 40px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background: white;
}

.GenreAddPage__form__item_text{
  width: 150px;
}

.GenreAddPage__form__item_input, .GenreAddPage__form__lepest__input{
  flex: 1 1 auto;
  margin-left: 10px;
  border: 0;
  background: none;
  border-bottom: 1px solid grey;
  padding: 8px 12px 4px 12px;
}

.GenreAddPage__form__lepest__input{
  width: 100%;
  padding: 8px 2px 4px 2px;
  margin: 0;
  flex: 0;
}

.GenreAddPage__form__lepest__item__row__item{
  display: flex;
  flex-direction: column;
  width: 12%;
  margin-right: 10px;
  justify-content: space-between;
}

.GenreAddPage__form__item_input:focus, .GenreAddPage__form__lepest__input:focus{
  border-bottom: 1px solid #2F80ED;
}

.GenreAddPage__form__lepest__item{
  min-height: 40px;
  margin-top: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  background: white;
}

.GenreAddPage__form__lepest__item__row{
  display: flex;
  justify-content: space-between;
}