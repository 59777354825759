.FimboLikes__item__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
}

.FimboLikes__item__row div{
  margin: 0 2px;
  overflow-x: auto;
}

.FimboLikes__item__row div::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}

.FimboLikes__item__row div::-webkit-scrollbar-thumb{
  background-color: #000000;
  border-radius: 10px;
}

.FimboLikes__item__name{
  width: 100px;
}

.FimboLikes__item__img{
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.FimboLikes__item__forImg{
  width: 30px;
}

.FimboLikes__item__likeCount{
  width: 160px;
}
.FimboLikes__diagram__container{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.FimboLikes__diagram__mainContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}