.AudioItemRow__row {
  width: 100%;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  background: white;
  padding: 5px 10px;
}

.AudioItemRow__row__color{
  background: rgb(238, 246, 255)
}

.AudioItemRow__row > * {
  display: flex;
  align-items: center;
  margin: 0 3px;
  overflow-x: auto;
  overflow-y: hidden;
}

.AudioItemRow__row > *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.AudioItemRow__row > *::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

.AudioItemRow__row__visible {
  width: 40px;
}

.AudioItemRow__row__id{
  width: 30px;
}

.AudioItemRow__row__position_weight{
  width: 40px;
}

.AudioItemRow__row__name{
  width: 120px;
}

.AudioItemRow__row__type{
  width: 110px;
}

.AudioItemRow__row__fimbo_name{
  width: 150px;
}

.AudioItemRow__row__fimbo_size{
  width: 140px;
}

.AudioItemRow__row__preview{
  width: 65px;
}

.AudioItemRow__row__path{
  width: 100px;
}

.AudioItemRow__row__edit{
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}